<template>
  <div>
    <div class="growth">
      <div class="inner">
        <div class="left-wrapper">
          <div class="title">Aggregators Accelerate Growth</div>
          <div class="content margin-top-15">Hyper-growth merchants like SeatGeek and Rappi focus on building a great customer experience and optimizing revenue from each transaction. Not on managing complex payment infrastructure or PCI and 3DS challenges.</div>
        </div>
        <img src="../../assets/images/use_cases_merchant_aggregators/p1.png" />
      </div>
    </div>
    <div class="orchestration">
      <div class="inner">
        <div class="title">
          <div>Our payment orchestration solution helps you:</div>
        </div>
        <div class="content-list">
          <template v-for="(item, index) in solutions">
            <div :key="index" class="content-item margin-top-50">
              <img class="icon" :src="item.icon" />
              <div class="content">{{item.content}}</div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="infrastructure">
      <div class="inner">
        <div class="title">
          <div>Minimize the Cost and Risk of Your Payments Infrastructure</div>
        </div>
        <div class="content">
          <div>To build a payment infrastructure that matches your business you need deep insight into security and regulatory standards. That redirects scarce development resources away from building differentiation within your category to maintaining a complex and dynamic payment platform.</div>
          <div class="margin-top-20">We help hundreds of global platforms stay secure and comply with PCI and other regulations. Moozumi enables access to hundreds of payment services from a single API. That means you can get to market faster with less demands on developers. </div>
        </div>
        <div class="list-items">
          <template v-for="(item, index) in infrastructure">
            <div :key="index" class="item">
              <img src="../../assets/images/use_cases_merchant_aggregators/icon.png" />
              <div class="text">{{item}}</div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="effect">
      <div class="grow">
        <div class="content-wrapper">
          <div class="title">
            <div>Land More Merchants on Your Network</div>
          </div>
          <div class="content">
            <div>Your merchants demand that you support their preferred payment services. After all, they have established PSP / gateway relationships they want to retain. And if you don’t support these services, merchants will go elsewhere.</div>
            <div class="margin-top-20">Moozumi supports virtually any payment service in a “bring your own gateway” model – without waiting for your teams to build connections. You can focus your developers on adding value and your sales people on landing more merchant customers.</div>
          </div>
        </div>
        <img src="../../assets/images/use_cases_merchant_aggregators/p2.png" />
      </div>
      <div class="optimize">
        <img src="../../assets/images/use_cases_merchant_aggregators/p3.png" />
        <div class="content-wrapper">
          <div class="title">
            <div>Increase Revenue from Your Merchant Network</div>
          </div>
          <div class="content">
            <div>Your merchant network depends on your platform to drive revenue. How can you help them increase sales and make your platform stickier? The answer is to offer value-added payment services. </div>
            <div class="margin-top-20">Moozumi’s platform lets you provide payment services like account updating and network tokens to refresh cards and keep customers transacting. Plus, smart routing and failover technology help avoid downtime and increase top-line revenue.</div>
          </div>
        </div>
      </div>
    </div>
    <MoozumiEmail></MoozumiEmail>
  </div>
</template>
<script>
import MoozumiEmail from '../../components/MoozumiEmail.vue'

export default {
  components: { MoozumiEmail },
  data () {
    return {
      solutions: [
        {
          icon: require('../../assets/images/use_cases_merchant_aggregators/icon1.png'),
          content: 'Reduce the cost and complexity of building a flexible, PCI-compliant payment system'
        },
        {
          icon: require('../../assets/images/use_cases_merchant_aggregators/icon2.png'),
          content: 'Attract more merchants by supporting the payment services they demand'
        },
        {
          icon: require('../../assets/images/use_cases_merchant_aggregators/icon3.png'),
          content: 'Maximize revenue by offering value-added payments services like routing and Account Updater'
        }
      ],
      infrastructure: [
        'Focus developers on building value instead of maintaining dozens of gateway integrations ',
        'Outsource compliance burdens via our secure, PCI-compliant vault and payment infrastructure‍',
        'Future-proof your strategy with a portable, adaptable payment platform that you control'
      ]
    }
  }
}
</script>
<style scoped lang="scss">
@import '../../styles/variables.scss';
.growth {
  background: url(../../assets/images/use_cases_merchant_aggregators/bg1.png);
  background-size: cover;
  padding: 90px 0;
  .inner {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .left-wrapper {
      width: 460px;
      max-width: 100%;
      .title {
        font-family: Helvetica-Bold;
        font-size: 52px;
        color: $primary-color1;
        letter-spacing: 0;
        text-align: left;
        line-height: 68px;
      }
      .content {
        font-family: HelveticaNeue;
        font-size: 18px;
        color: $content-color;
        letter-spacing: 0;
        text-align: left;
        line-height: 36px;
      }
    }
    img {
      width: 640px;
      max-width: 100%;
      box-sizing: border-box;
    }
  }
}
.orchestration {
  padding: 120px 0;
  background: url(../../assets/images/use_cases_merchant_aggregators/bg2.png);
  background-size: cover;
  .inner {
    width: 1200px;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    .title {
      font-family: HelveticaNeue-Bold;
      font-size: 36px;
      color: $content-color;
      letter-spacing: 0;
      text-align: left;
      line-height: 52px;
      position: relative;
      &:before {
        content: '';
        width: 140px;
        height: 6px;
        display: block;
        background-image: linear-gradient(44deg, #02C1FE 0%, #1B55E8 100%);
        border-radius: 5px;
        margin-bottom: 50px;
      }
    }
    .content-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .content-item {
        width: 330px;
        box-shadow: 0 0 77px 0 rgb(0 0 0 / 6%);
        background: $white;
        border-radius: 20px;
        text-align: left;
        padding: 32px 24px;
        .icon {
          width: 60px;
        }
        .content {
          font-family: HelveticaNeue;
          font-size: 20px;
          color: $nav-title-color;
          letter-spacing: 0;
          text-align: left;
          line-height: 36px;
          margin-top: 24px;
        }
      }
    }
  }
}
.infrastructure {
  padding: 120px 0;
  background: url(../../assets/images/use_cases_merchant_aggregators/bg3.png);
  background-size: cover;
  .inner {
    width: 1200px;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    .title {
      font-family: HelveticaNeue-Bold;
      font-size: 36px;
      color: #FFFFFF;
      letter-spacing: 0;
      text-align: left;
      line-height: 52px;
      position: relative;
      &:before {
        content: '';
        width: 140px;
        height: 6px;
        display: block;
        background-image: linear-gradient(44deg, #02C1FE 0%, #1B55E8 100%);
        border-radius: 5px;
        margin-bottom: 50px;
      }
    }
    .content {
      margin-top: 15px;
      font-family: HelveticaNeue;
      font-size: 20px;
      color: $white;
      letter-spacing: 0;
      text-align: left;
      line-height: 36px;
    }
    .list-items {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item {
        border-left: 1px solid #2A6095;
        width: 350px;
        margin-top: 50px;
        font-family: HelveticaNeue;
        font-size: 20px;
        color: $white;
        letter-spacing: 0;
        text-align: left;
        line-height: 32px;
        display: flex;
        justify-content: space-around;
        img {
          width: 19px;
          height: 19px;
        }
        .text {
          width: 300px;
          margin-top: -6px;
        }
      }
    }
  }
}
.effect {
  padding: 60px 0;
  background: url(../../assets/images/use_cases_merchant/bg4.png);
  background-size: cover;
  .grow, .optimize {
    padding: 60px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    img {
      width: 462px;
      max-width: 100%;
      box-sizing: border-box;
    }
    .title {
      font-family: HelveticaNeue-Bold;
      font-size: 36px;
      color: $content-color;
      letter-spacing: 0;
      text-align: left;
      line-height: 52px;
      position: relative;
      &:before {
        content: '';
        width: 140px;
        height: 6px;
        display: block;
        background-image: linear-gradient(44deg, #02C1FE 0%, #1B55E8 100%);
        border-radius: 5px;
        margin-bottom: 50px;
      }
    }
    .content-wrapper {
      width: 602px;
      max-width: 100%;
      box-sizing: border-box;
      .content {
        font-family: HelveticaNeue;
        font-size: 20px;
        color: $primary-color;
        letter-spacing: 0;
        text-align: left;
        line-height: 36px;
        margin-top: 16px;
      }
    }
  }
  .optimize {
    align-items: center;
  }
}
@media screen and (max-width: 1200px) {
  .growth {
    .inner {
      padding: 0 15px;
    }
  }
  .orchestration {
    .inner {
      padding: 0 15px;
    }
  }
  .infrastructure {
    .inner {
      padding: 0 15px;
    }
  }
  .effect {
    .grow, .optimize {
      padding-left: 15px;
      padding-right: 15px;
    }
    .grow {
      img {
        margin-top: 20px;
      }
    }
    .optimize {
      .content-wrapper {
        margin-top: 20px;
      }
    }
  }
}
</style>
